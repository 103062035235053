import React from 'react'
import './Forms.scss'

function Input (props) {
    const { type, name, value, placeholder, onChange, required } = props;
    return (
        <input 
            type={ type ? type : 'text' }
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            required={required} 
        />
    )
}

export default Input