import React from 'react'
import './Strengths.scss'

import Trucks from '../../../assets/images/track-2.png'
import Hands from '../../../assets/images/hands.png'
import Hand from '../../../assets/images/hand-2.png'
import HandsIcon from '../../../assets/icons/hands-icon.png'
import LorryIcon from '../../../assets/icons/lorry-icon.png'
import Clock from '../../../assets/icons/clock.png'

function Strengths () {
    return (
        <div className='strengths'>
            <div className='row experienced_trusted_reliable'>
               <div className='col-md-4'>
                   <div className='content' style={{color:'#ffffff'}}>
                       <img src={Trucks} alt='trucks' style={{width:'100%'}}/>
                        <section className='image-text'>
                            <h2 className="trust-header">Experienced</h2>
                            <p>
                            With other 5 years experience promptly and safer transporting cargo, you
                            can rest easy while we deliver the best logistics services
                            </p>
                            <p>Read more <i className="social fa fa-long-arrow-right"></i> </p>
                        </section>
                   </div>
               </div>
               <div className='col-md-4'>
                   <div className='content' style={{color:'#ffffff'}}>
                        <img src={Hands} alt='trucks' style={{width:'100%'}}/>
                        <section className='image-text'>
                            <h2 className="trust-header">Trusted</h2>
                            <p>
                            Our years of delivering both dry cargo and dangerours goods speaks for
                            itself. A record you can trust us
                            </p>
                            <p>Read more <i className="social fa fa-long-arrow-right"></i> </p>
                        </section>
                   </div>
               </div>
               <div className='col-md-4'>
                   <div className='content'>
                        <img src={Hand} alt='trucks' style={{height:'20vh', width:'40%', float:'right', margin:'5vh 5%'}}/>
                        <section className='image-text'>
                            <h2 className="trust-header">Reliable</h2>
                            <p>
                            We are committed to delivering and deliver your proptly and safely. We
                            are a company you can rely on
                            </p>
                            <p>Read more <i className="social fa fa-long-arrow-right"></i> </p>
                        </section>
                   </div>
               </div>
            </div>

            <div className='row strengths_2'>
                <div className='col-md-6'>
                    <h2>Real Solutions, real fast</h2>
                    <h1>Delivering the Best Global Logistics Solutions.</h1>
                </div>
                <div className='col-md-6'>
                    <p>Our global logistics expertise, advanced supply chain technology & customized logistics 
                        solutions will help you analyze, develop and implement successful supply chain management 
                        strategies from end-to-end.</p>
                </div>
            </div>
            <div className='row strengths_3'>
                <h1 style={{marginBottom: '3rem'}}>Our Strengths</h1>
                <div className='col-md-4'>
                    <div className='content'>
                        <img src={HandsIcon} alt='' height='50'/>
                        <h3>Reliability</h3>
                        <p>
                        Our standards ensure that you receive quality service every
                        time you transport with us
                        </p>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='content'>
                        <img src={LorryIcon} alt='' height='50'/>
                        <h3>Safe Delivery</h3>
                        <p>
                        We know you want all your cargo in one piece, and we work everyday
                        to ensure this!
                        </p>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='content'>
                        <img src={Clock} alt='' height='50'/>
                        <h3>Fast Service</h3>
                        <p>
                        Is your cargo time sensitive? Work with us to ensure you're always
                        on time
                        </p>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default Strengths
