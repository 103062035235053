import React from 'react'
import './LandingPage.scss'

// components
import MainSlide from '../../components/MainSlide/MainSlide'
import Strengths from '../../components/Strengths/Strengths'
import Reach from '../../components/Reach/Reach'
import Clients from '../../components/Clients/Clients'
import Wide from '../../components/Wide/Wide'
import Map from '../../components/Map/Map'
import Quote from '../../components/Quote/Quote'
import Footer from '../../components/Footer/Footer'

function LandingPage () {
    return (
        <div className='landing_page'>
            <MainSlide/>
            <Strengths/>
            <Reach/>
            <Clients/>
            <Wide/>
            <Map/>
            <Quote/>
            <Footer/>
        </div>
    )
}

export default LandingPage
