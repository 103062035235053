import React from 'react'
import './ErrorPage.scss'

function ErrorPage () {
    return (
        <div className='error_page'>
            <h1>(404)</h1>
            <h3>Sorry, the page you're looking for cannot be found</h3>
        </div>
    )
}

export default ErrorPage
