import React from 'react'
import './Map.scss'

import MapView from '../../../assets/images/map.png'

function Map () {
    return (
        <div className='map'>
            <div className='row'>
                <div className='col-md-4'>
                    <div className='locations'>
                        <div className='locations_header'>
                            <h3>Our Locations</h3>
                        </div>
                        <div className='body'>
                            <p>Zambia</p>
                            <p>DRC</p>
                            <p>Swaziland</p>
                        </div>
                    </div>
                </div>
                <div className='col-md-8'>
                    <img src={MapView} alt='' />
                </div>
            </div>
        </div>
    )
}

export default Map
