import React from 'react'
import './Wide.scss'

import Truck from '../../../assets/images/truck-4.png'

function Wide () {
    return (
        <div className='wide'>
            <div className='row'>
                <div className='col-md-6'>
                    <img src={Truck} alt='truck' />
                </div>
                <div className='col-md-6'>
                    <div className='content'>
                        <h3>Real solutions, real fast</h3>
                        <h1>Real solutions, real fast</h1>
                        <p>Our global logistics expertise, advanced supply chain technology & customized logistics 
                            solutions will help you analyze, develop and implement successful supply chain management 
                            strategies from end-to-end.
                        </p>

                        <div className='col-md-4'>
                            <div className='content'>
                                <h1>40</h1>
                                <b>Major clients</b>
                                <p>
                                We are committed to provide you with safe
                                dependable freight logistic services, we strive to maintain long-standing
                                valuable relationships
                                </p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='content'>
                                <h1>150+</h1>
                                <b>Flat bed trucks</b>
                                <p>
                                We have over 150 modern trucks with carrying
                                capacity of 40 tons per truck. We regularly inspect and service our trucks
                                to ensure safe and on time delivery of the cargo
                                </p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='content'>
                                <h1>78+</h1>
                                <b>Acid tankers</b>
                                <p>
                                We are capable of delivering your goods on time and in perfect
                                condition our seventy eight acid tankers have the carrying capacity of
                                30 to 40 mt.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Wide
