import React from 'react'
import './Preloader.scss'

import { ReactComponent as Lorry } from '../../../assets/icons/lorry.svg'

function Preloader () {
    return (
        <div className='preloader'>
            <Lorry className='lorry animated zoomInRight'/> <br/>
            <div className="lds_ripple"><div></div><div></div></div>
        </div>
    )
}

export default Preloader
