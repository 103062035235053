import React, { useState } from 'react'
import './Enquiry.scss'

import Loader from '../../components/Loader/Loader'
import Button from '../../components/Forms/Button'

function Enquiry () {
    const [ loading, setLoading ] = useState(false)

    const handleSubmit = e => {
        e.preventDefault();
        setLoading(true)
    }

    return (
        <div className='row enquiry'>
            <form onSubmit={handleSubmit}>
                <div className='col-md-6'>
                    <input type='text' placeholder='First Name' required/>
                </div>
                <div className='col-md-6'>
                    <input type='text' placeholder='Last Name' required/>
                </div>
                <div className='col-md-6'>
                <input type='number' placeholder='Phone Number' required/>
                </div>
                <div className='col-md-6'>
                    <input type='email' placeholder='Email' required/>
                </div>
                <div className='col-md-6'>
                    <input type='text' placeholder='Role/Title' required/>
                </div>
                <div className='col-md-6'>
                    <input type='text' placeholder='Type of Enquiry' required/>
                </div>
                <textarea placeholder='Tell us more'></textarea>
                <Button label={ loading ? <Loader/> : 'Learn More' }/>
            </form>
        </div>
    )
}

export default Enquiry
