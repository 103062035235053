import React from 'react'
import './Footer.scss'

import Logo from '../../../assets/images/logo.png'
import { NavLink } from 'react-router-dom'

function Footer () {
    return (
        <div className='footer'>
            <div className='row'>
                <div className='col-md-4'>
                    <div className='content'>
                        <h3>Access Logistics</h3>
                        <img src={Logo} alt='' height='50'/>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='content'>
                        <h3>Connect with us</h3>
                        <div className='social'>
                            <a href="https://m.facebook.com/accesslogisticzambia"><i className="social fa fa-facebook animated fadeInDown"></i></a>
                            <a href="https://www.instagram.com/accesslogistic/"><i className="social fa fa-instagram animated fadeInDown"></i></a>
                            {/* <a href="wt"><i className="social fa fa-whatsapp animated fadeInDown"></i></a>
                            <a href="tw"><i className="social fa fa-twitter animated fadeInDown"></i></a> */}
                        </div>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='content'>
                        <h3>Links</h3>
                        <NavLink to='/' className='nav-link'>Home</NavLink>  <br/>
                        <NavLink to='/' className='nav-link'>About</NavLink> <br/>
                        <NavLink to='/' className='nav-link'>Our Services</NavLink> <br/>
                        <NavLink to='/' className='nav-link'>Request for a service</NavLink> <br/>
                        <NavLink to='/' className='nav-link'>Contact us</NavLink> <br/>
                    </div>
                </div>
                {/* <div className='col-md-3'>
                    <div className='content'>
                        <h3>Contact info</h3>
                        <p>Box 3233</p> 
                        <p>1810 Kings Way</p>
                        <p>King Street, 5th Avenue, Zambia</p>
                        <p>+1-2355-3345-5 contact@accesslogistics.com</p>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default Footer
