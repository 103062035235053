import React, { useState } from 'react'
import './Tracking.scss'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import Loader from '../Loader/Loader'
import Input  from '../Forms/Input'
import Button from '../Forms/Button'

function Tracking () {
    const [ loading, setLoading ] = useState(false)

    const handleSubmit = e => {
        e.preventDefault();
        setLoading(true)
    }


    return (
        <div className='tracking'>
            <Tabs>
                <TabList>
                    <Tab>Track your load</Tab>
                    <Tab>Enquiries</Tab>
                </TabList>
                <TabPanel>
                    <form onSubmit={handleSubmit}>
                        <Input type='text' placeholder='Enter your tracking no.' required/> <br/>
                        <Button label={ loading ? <Loader/> : 'Track Now' }/>
                    </form>
                </TabPanel>
                <TabPanel>
                    <p>Enquiries</p>
                </TabPanel>
            </Tabs>
        </div>
    )
}

export default Tracking
