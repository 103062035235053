import React from 'react'
import './Clients.scss'

import Lorry from '../../../assets/images/lorry-3.png'

import client1 from '../../../assets/images/Group 322.png';
import client2 from '../../../assets/images/Group 323.png'
import client3 from '../../../assets/images/Group 324.png'
import client4 from '../../../assets/images/Group 325.png'

function Clients () {
    return (
        <div className='clients'>
            <h4>Our esteemed clients</h4>
            <h1>Our Clients</h1>
            <div className='row'>
                <div className='col-md-3'><img src={client1} alt="client" className='clients-image' height='50'/></div>
                <div className='col-md-3'><img src={client2} alt="client" className='clients-image' height='50'/></div>
                <div className='col-md-3'><img src={client3} alt="client" className='clients-image' height='50'/></div>
                <div className='col-md-3'><img src={client4} alt="client" className='clients-image' height='50'/></div>
            </div>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='content'>
                        <h4>Why choose us</h4>
                        <h1>Industry Solutions</h1>
                        <p>As one of the leading providers of logistics solutions across the globe, 
                            Access Logistics has an immense portfolio of transport and contract logistics 
                            solutions that spans multiple industries.
                        </p>
                    </div>
                </div>
                <div className='col-md-6'>
                    <img src={Lorry} alt='' className='lorry-img'/>
                </div>
            </div>
        </div>
    )
}
export default Clients