import React, { useState }from 'react'
import './Quote.scss'

import Loader from '../../components/Loader/Loader'
import Button from '../../components/Forms/Button'
import Woman from '../../../assets/images/woman.png'

function Quote () {
    const [ loadingEmail, setLoadingEmail ] = useState(false)
    const [ loading, setLoading ] = useState(false)

    const handleSubmitEmail = e => {
        e.preventDefault();
        setLoadingEmail(true)
    }
    const handleSubmit = e => {
        e.preventDefault();
        setLoading(true)
    }


    return (
        <div className='quote'>
                <div className='row'>
                    <div className='col-md-4'>
                        <div className='content'>
                            <h3>Our Newsletter</h3>
                            <p>Sign up for industry alerts, news and insghts</p>
                        </div>
                    </div>
                    <div className='col-md-8'>
                        <div className='content'>
                            <form onSubmit={handleSubmitEmail}>
                                <input type='text' placeholder='Your name'/>
                                <input type='text' placeholder='Email Address'/>
                                <button>{ loadingEmail ? <Loader/> : 'Submit' }</button>
                            </form>
                        </div>
                    </div>
                </div>
                <div className='row get_in_touch'>
                    <div className='col-md-6'>
                        <div className='content'>
                            <h3>Get in touch with us</h3>
                            <h1>Request for a quote</h1>
                            <p>
                            Give your contact and cargo details and we will reach out to you
                            shortly. Thank you
                            </p>
                            <form onSubmit={handleSubmit}>
                                <input type='text' placeholder='Your name' required/>
                                <input type='text' placeholder='Email Address' required/>
                                <input type='text' placeholder='Phone no.' required/>
                                <input type='text' placeholder='Cargo type' required/>
                                <input type='text' placeholder='Origin' required/>
                                <input type='text' placeholder='Destination' required/> <br/>
                                <Button label={ loading ? <Loader/> : 'Submit Now' }/>
                            </form>
                        </div>
                    </div>
                    <div className='col-md-6 woman'>
                        <img src={Woman} alt=''/>
                    </div>
                </div>
        </div>
    )
}

export default Quote
