import React from 'react'
import './Reach.scss'

import Africa from '../../../assets/images/africa.png'

function Reach () {
    return (
        <div className='reach'>
            <div className='overlay'>
                <div className='row'>
                    <div className='col-md-6'>
                        <img src={Africa} alt='africa map' />
                    </div>
                    <div className='col-md-6'>
                       <div className='content'>
                           <h2>Real solutions, real fast</h2>
                           <h1>Our wide reach across Africa</h1>
                           <p>Our global logistics expertise, advanced supply chain technology & customized 
                               logistics solutions will help you analyze, develop and implement successful 
                               supply chain management strategies from end-to-end.
                            </p>
                            <button>Learn more</button>
                       </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reach
