import React from 'react'
import './Navbar.scss'
import { NavLink } from 'react-router-dom'

import Logo from '../../../assets/images/logo.png'
import Button from '../../components/Forms/Button'

function Navbar () {
    return (
        <div className='navbar'>
            <nav className="navbar navbar-default">
                <div className="container">
                    <div className="navbar-header">
                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>                        
                    </button>
                    <img src={Logo} className="logo" alt='logo' height="50"/>
                    </div>
                    <div className="collapse navbar-collapse" id="myNavbar">
                    <ul className="nav navbar-nav navbar-right">
                        <li><NavLink to='/'>Home</NavLink></li>
                        <li><NavLink to='/about'>About</NavLink></li>
                        <li><NavLink to='/'>Our Services</NavLink></li>
                        <li><NavLink to='/'>Our Reach</NavLink></li>
                        <li><NavLink to='/contact'>Contact  Us</NavLink></li>
                        <li><Button className='quote_btn' label='Request for Quote'/></li>
                        <li><i className="social fa fa-search"></i></li>
                    </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Navbar
