import React from 'react'
import './About.scss'

import { NavLink } from 'react-router-dom'

import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
// images
import TruckImage from '../../../assets/images/trucks-many-2.png'
import Map from '../../../assets/images/map.png'
import client1 from '../../../assets/images/Group 322.png';
import client2 from '../../../assets/images/Group 323.png'
import client3 from '../../../assets/images/Group 324.png'
import client4 from '../../../assets/images/Group 325.png'


function About () {
    return (
        <div className='about'>
            <Header heading='About Us'/>
            <div className='row section_one'>
                <div className='col-md-6'>
                    <div className='content'>
                        <h3>14 years of experience</h3>
                        <h1>Delivering <span>Prompt</span> and <span>Reliable </span> Logistics Services since 2006.</h1>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='content'>
                        <p>Access Logistic SARL was established in 2006, starting with 1 tanker and 2 flatbeds as a leasing company, 
                        we have increased our fleet to the present 35 tankers and 50 flat beds serving a diverse portfolio of clients in 
                        Central Africa. The Management team of Access Logistics SARL is an energetic and highly experienced group, 
                        with years of extensive transport and logistics experience. We specialize in cargo transport servicing all 
                        over central Africa and on forwarding services to other regions. We utilize road services to offer prompt and 
                        reliable services, through to warehousing and distribution
                        </p>
                    </div>
                </div>
            </div>
            <div className='row section_two'>
                <div className='col-md-6'>
                    <img src={TruckImage} alt='truck' />
                </div>
                <div className='col-md-6 section_two_inner'>
                    <div className='content'>
                        <h3>Diversity you can rely on</h3>
                        <h1>Our Fleet</h1>
                        <p>Starting with 1 tanker and 2 flatbeds as a leasing company, we have increased our fleet to the present 35 tankers and 50 flat beds serving a diverse portfolio of clients in Central Africa.</p>
                        <div className='col-md-6'>
                            <h1>78+</h1>
                            <h3>Tankers</h3>
                            <p>
                            We are capable of delivering our goods on time and in perfect, condition
                            our seventy eight acid tankers have the carrying capacity of 30
                            to 40 mt.
                            </p>
                        </div>
                        <div className='col-md-6'>
                            <h1>150+</h1>
                            <h3>Flatbeds</h3>
                            <p>
                            We have our 150 modern trucks with carrying capacity of 40 tons per truck.
                            We reqularly inspect and service our trucks to ensure safe and on time
                            delivery of the cargo
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row section_three'>
                <h3>Our esteemed clients</h3>
                <h1>Our Clients</h1>
            <div className='row section_small'>
                <div className='col-md-3'><img src={client1} alt="client"/></div>
                <div className='col-md-3'><img src={client2} alt="client"/></div>
                <div className='col-md-3'><img src={client3} alt="client"/></div>
                <div className='col-md-3'><img src={client4} alt="client"/></div>
            </div>
            </div>
            <div className='row section_five'>
                <div className='col-md-6 locations'>
                    <div className='overlay'>
                        <div className='centered'>
                            <h1>Our Locations</h1>
                            <p>Supported by our own offices and associates in Africa, we employ a team of professionals that follow up and follow through on each and every transaction we manage</p>
                            <NavLink to='/contact' className='nav-link'><button>Contact Us</button></NavLink>
                        </div>
                    </div>
                </div>
                <div className='col-md-6 map'>
                    <img src={Map} alt='map' />
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default About