import React from 'react'
import './MainSlide.scss'

import { NavLink } from 'react-router-dom'

// components
import Navbar from '../NavigationBar/Navbar'
import Tracking from '../Tracking/Tracking'

function MainSlide () {
    return (
        <div className='main_slide'>
            <div className='overlay'>
                <Navbar/>
                <div className='centered row'>
                    <div className='col-md-6'>
                        <h1>Fast, Certified and Flexile logistics solutions for Africa</h1>
                        <p>Our global logistics expertise, advanced supply chain technology & customized 
                            logistics solutions will help you analyze, develop and implement successful 
                            supply chain management strategies from end-to-end.
                        </p>
                        <button className='services_btn'>Our Services</button>
                        <NavLink to='/about' className='nav-link'><button className='about_btn'>About us</button></NavLink>
                    </div>
                    <div className='col-md-6'>
                        <Tracking/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainSlide
