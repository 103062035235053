import React, { useState, useEffect } from 'react';

//Website Pages
import LandingPage from './Website/pages/LandingPage/LandingPage'
import About from './Website/pages/About/About'
import Contact from './Website/pages/Contact/Contact'
import ErrorPage from './Website/pages/404/ErrorPage'

// components
import Preloader from './Website/components/Preloader/Preloader'

// routing
import { BrowserRouter, Switch, Route } from 'react-router-dom';

function App() {
    //preloader set-state
    const [ spinner, setSpinner ] = useState(true);

    useEffect(() => {
      // preloader (set timeout)
      setTimeout(() => setSpinner(false), 1000)
    },[])

    // preloader (render)
  if(spinner) {
    return <Preloader/>
  } else {
    return (
      <BrowserRouter>
        <Switch>
          <Route path='/' component={LandingPage} exact />
          <Route path='/about' component={About} exact />
          <Route path='/contact' component={Contact} exact />
          <Route component={ErrorPage} exact />
        </Switch>
      </BrowserRouter>
    )
  }
}

export default App;
