import React from 'react'
import './Contact.scss'

import Header from '../../components/Header/Header'
import Enquiry from '../../components/Enquiry/Enquiry'
import Footer from '../../components/Footer/Footer'

import Marker  from '../../../assets/icons/map-marker.png'
import Call from '../../../assets/icons/call.png'
import Envelop from '../../../assets/icons/envelop.png'

function Contact () {
    return (
        <div className='contact'>
            <Header heading='Contact Us'/>
            <div className='row section_one'>
                <div className='col-md-6'>
                    <div className='content'>
                        <h2>Zambia Office</h2>
                        <p><span><img src={Marker} alt='' height='20'/></span> 
                        Plot# 5284,Nakambala Cresent, industrial
                        area, Ndola-Zambia
                        </p>
                        <p><span><img src={Call} alt='' height='20'/></span> <a href="tel:+260 978 795 891">+260 978 795 891</a></p>
                        <p>
                            <span>
                            <img src={Envelop} alt='' height='20'/>
                            </span>
                            <a 
                            href = "mailto: info@accesslogistic.com"
                            >info@accesslogistic.com</a>
                        </p>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='content'>
                        <h2>D.R.C Office </h2>
                        <p><span><img src={Marker} alt='' height='20'/></span> 
                        Plot 1147, Route Likasi, Quatier kimbembe,
                        Commune Annex, Lubumbashi Katanga Provice - DRC
                        </p>
                        <p><span><img src={Call} alt='' height='20'/></span> <a href="tel:+260 977 501 061">+260 977 501 061</a></p>
                        <p><span><img src={Envelop} alt='' height='20'/></span>
                         <a 
                            href = "mailto: arhmon.simumbwe@accesslogistic.com"
                            >arhmon.simumbwe@accesslogistic.com</a>
                         </p>
                    </div>
                </div>
            </div>
            <div className='section_two'>
                <div className='overlay'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='content'>
                                <h2>Give us your contact details and we will contact you soon</h2>
                                <p>
                                Tell us what you need and one of our representatives will get back to you
                                shortly
                                </p>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='enquiry'>
                                <Enquiry/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Contact