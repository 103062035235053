import React from 'react'
import './Header.scss'

import NavBar from '../../components/NavigationBar/Navbar'

function Header (props) {
    return (
        <div className='header'>
            <div className='overlay'>
                <NavBar/>
                <h1>{props.heading}</h1>
            </div>
        </div>
    )
}

export default Header